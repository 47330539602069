<template>
  <div>
    <h2 class="mb-4">Новый промокод</h2>

    <v-sheet class="pa-4" elevation="3">
      <promocode-form
        :promocode="promocode"
        :loading="loading"
        @change-field="changeField"
        @submit="createPromocodes"
        @clear="clear"
      />
    </v-sheet>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import { createPromocodeDto } from "~/libs/promocode/create-promocode-dto";
import { Promocode } from "~/libs/promocode/promocode";

import PromocodeForm from "~/components/promocodes/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      loading: false,

      promocode: { ...createPromocodeDto }
    };
  },
  methods: {
    async createPromocodes() {
      this.loading = true;

      try {
        await this.$axios.$post("discounts/promo-codes", Promocode.to(this.promocode));

        this.clear();

        this.$snackbar.success("Промокод создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.promocode)).toHtml();

        this.$snackbar.error("Не удалось создать промокод: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.promocode = { ...createPromocodeDto };
    },
    changeField(payload) {
      this.commitChange(this.promocode, payload);
    }
  },
  components: {
    PromocodeForm
  }
};
</script>
