import { formatNumber } from "~/core/filters/numbers";

export const promocodeDiscountTypes = [
  { name: "relative", title: "Относительный (%)" },
  { name: "absolute", title: "Абсолютный (₽)" }
];

export const createPromocodeDiscountDto = {
  type: null,
  product: null,
  value: "",
  trial: null
};

export function createDiscountHint(discount) {
  if (!discount.value) return "";

  if (discount.type === "relative") {
    return formatNumber(discount.value) + "%";
  } else if (discount.type === "absolute") {
    return formatNumber(discount.value) + " ₽";
  }

  return "";
}

Object.freeze(promocodeDiscountTypes);
Object.freeze(createPromocodeDiscountDto);
