<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Продукт</th>
          <th>Тип</th>
          <th>Значение</th>
          <th>Пробный период</th>
          <th>&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(discount, ind) of value" :key="ind">
          <td>
            <div class="py-2">
              <v-select
                :value="discount.product"
                :items="products"
                item-value="name"
                item-text="title"
                placeholder="Продукт"
                dense
                @input="changeField(ind, 'product', $event)"
              />
            </div>
          </td>

          <td>
            <div class="py-2">
              <v-select
                :value="discount.type"
                :items="promocodeDiscountTypes"
                item-value="name"
                item-text="title"
                placeholder="Тип скидки"
                dense
                @input="changeField(ind, 'type', $event)"
              />
            </div>
          </td>

          <td>
            <div class="py-2">
              <v-text-field
                type="number"
                step="0.01"
                :value="discount.value"
                :hint="createDiscountHint(discount)"
                persistent-hint
                dense
                placeholder="Значение"
                @input="changeField(ind, 'value', $event)"
              />
            </div>
          </td>

          <td>
            <div class="py-2">
              <v-text-field
                type="number"
                step="1"
                :value="discount.trial"
                hint="Если не указано - по тарифу"
                persistent-hint
                dense
                placeholder="В днях"
                @input="changeField(ind, 'trial', $event)"
              />
            </div>
          </td>

          <td>
            <v-btn class="ml-2" icon @click="removeDiscount(ind)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>

        <tr>
          <td class="text-center" colspan="10">
            <v-btn color="primary" @click="createDiscount">Добавить продукт</v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import {
  createDiscountHint,
  createPromocodeDiscountDto,
  promocodeDiscountTypes
} from "~/libs/promocode/promocode-discount";

export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    products() {
      return this.$store.state.dictionary.products;
    },
    promocodeDiscountTypes() {
      return promocodeDiscountTypes;
    }
  },
  methods: {
    createDiscount() {
      this.updateValue(nextValue => {
        return [...nextValue, createPromocodeDiscountDto];
      });
    },
    changeField(ind, field, value) {
      this.updateValue(nextValue => {
        nextValue[ind][field] = value;

        return nextValue;
      });
    },
    removeDiscount(ind) {
      this.updateValue(nextValue => {
        nextValue.splice(ind, 1);

        return nextValue;
      });
    },
    updateValue(cb) {
      const nextValue = JSON.parse(JSON.stringify(this.value));

      this.$emit("input", cb(nextValue));
    },

    createDiscountHint(discount) {
      return createDiscountHint(discount);
    }
  }
};
</script>
