<template>
  <v-form autocomplete="off" @submit.prevent="$emit('submit')">
    <v-text-field :value="promocode.name" label="Имя" :disabled="isUpdating" @input="setField('name', $event)" />

    <v-text-field :value="promocode.description" label="Описание" @input="setField('description', $event)" />

    <date-picker v-model="promocode.expiredAt" label="Дата истечения" use-time clearable />

    <v-checkbox v-model="isActiveProxy" label="Активность" />

    <discounts-picker v-model="discountsProxy" />

    <div class="mt-4 text-right">
      <v-btn v-if="isCreating" class="mr-4" @click="$emit('clear')">Сброс</v-btn>
      <v-btn type="submit" color="accent" :loading="loading">Сохранить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import DiscountsPicker from "./discount-picker";

export default {
  mixins: [editorFields],
  props: {
    promocode: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isCreating() {
      return this.$route.name === "promocodes-create";
    },
    isUpdating() {
      return !this.isCreating;
    },

    isActiveProxy: {
      get() {
        return this.promocode.isActive;
      },
      set(value) {
        this.setField("isActive", value);
      }
    },
    discountsProxy: {
      get() {
        return this.promocode.discounts;
      },
      set(value) {
        this.setField("discounts", value);
      }
    }
  },
  components: {
    DiscountsPicker
  }
};
</script>
